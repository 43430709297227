import { useTranslation } from "react-i18next";

const NetTotal = ({ values, setValues }) => {
  const { t } = useTranslation();

  const KEYS = ["net_income", "dna", "depreciations", "investments", "provisions_fixed_assets"];
  const KEYS_LABEL = {
    net_income: t("business_plan.net_income.title"),
    dna: t("business_plan.dna.title"),
    depreciations: t("business_plan.depreciations.title"),
    investments: t("business_plan.ebitda.investments"),
    provisions_fixed_assets: t("business_plan.provisions_fixed_assets.title"),
  };

  const years = Array.from(Array(11).keys()).map((i) => (values?.year_4 || 0) - 4 + i);

  const parseToNumber = (value) => {
    if (value === "") return 0;
    if (value.includes(",") && !value.includes(".")) value = value.replace(",", ".");
    if (value.includes(" ")) value = value.replace(" ", "");
    return Number(value);
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {t("business_plan.ebitda.past_balance_sheet")}
            </th>
            <th colSpan="7" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {t("business_plan.ebitda.predicted_balance_sheet")}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{t("business_plan.ebitda.year")}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-36">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => (
                <td key={year} className={`border border-secondary text-xs px-2 ${1 < index && index < 5 ? "bg-orange-100" : ""} `}>
                  <input
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-none"
                    value={values[`${key}_${index}`] || 0}
                    onChange={(e) => setValues({ ...values, [`${key}_${index}`]: parseToNumber(e.target.value) })}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NetTotal;
