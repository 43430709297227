import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import api from "@/services/api";
import Loader from "@/components/Loader";

const InterviewScheduled = () => {
  const { companyId } = useParams();
  const [values, setValues] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${companyId}`);
        if (!res.ok) throw new Error("Une erreur s'est produite lors de la récupération des données de l'entreprise.");
        setValues(res.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [companyId]);

  const handleBookAgain = async () => {
    try {
      const res = await api.put(`/company/${values._id}`, { valuation_step: "INTERVIEW" });
      if (!res.ok) throw new Error("Une erreur s'est produite lors de la récupération des données de l'entreprise.");
      setValues(res.data);
      navigate("../interview");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40 flex flex-col items-center">
      <h1 className="font-semibold text-xl">En attente de l'entretien</h1>
      <p className="text-base text-gray-500 text-center">
        Vous avez pris rendez-vous pour l'entretien. Lorsque l'entretien sera terminé, vous pourrez accéder aux résultats de la valorisation.
      </p>
      <button type="button" className="primary-button" onClick={handleBookAgain}>
        Reprendre un rendez-vous
      </button>
    </div>
  );
};

export default InterviewScheduled;
