import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";

const NwcTable = ({ values, setValues, computedValues }) => {
  const { t } = useTranslation();

  const KEYS = ["working_capital"];
  const KEYS_LABEL = {
    working_capital: t("business_plan.working_capital.title"),
  };

  const years = Array.from(Array(11).keys()).map((i) => (values?.year_4 || 0) - 4 + i);

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="4" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {t("business_plan.ebitda.past_balance_sheet")}
            </th>
            <th colSpan="7" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {t("business_plan.ebitda.predicted_balance_sheet")}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{t("business_plan.ebitda.year")}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-36">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => (
                <td key={year} className={`border border-secondary text-xs px-2 ${1 < index && index < 5 ? "bg-orange-100" : ""} `}>
                  <CurrencyInput
                    intlConfig={{ locale: "fr-FR" }}
                    decimalsLimit={2}
                    className="w-full h-full appearance-none bg-transparent border-none text-right focus:outline-non font-medium tracking-wide"
                    value={values[`${key}_${index}`] || 0}
                    onValueChange={(_, name, value) => setValues((prev) => ({ ...prev, [`${key}_${index}`]: value.float }))}
                  />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td className="border p-2 text-xs w-56">{t("business_plan.net_working_capital.title")}</td>
            {years.map((year, index) => (
              <td key={year} className="bg-gray-100 text-xs px-2 text-right border">
                {computedValues.net_working_capital[index]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NwcTable;
